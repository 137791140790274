import website from '../assets/luna.png'
import instagram from '../assets/instagram.png'
//import appstore from '../assets/IMG_20201124_112759.png'
import youtube from '../assets/images (3).jpeg'
//import dribbble from '../assets/dribbble.png'
import github from '../assets/github.png'
//import telegram from '../assets/IMG_20201124_104554.png'
import discord from '../assets/dipscord.png'
import kofi from '../assets/kofi.png'
import tebex from '../assets/tebex.png'

const items = [{
    "title": "Discord",
    "subtitle": "akino | Feel free to add me! | (Tap to open my profile)",
    "image": discord,
    "link": "https://discord.com/users/85809275888209920"
},
{
    "title": "Instagram",
    "subtitle": "@forearms_international | Forearms. All day, every day.",
    "image": instagram,
    "link": "https://instagram.com/forearms_international"
},
// {
//     "title": "Website",
//     "subtitle": "I'll make an updated webisite soon™",
//     "image": website,
//     "link": "#"
// },
{
    "title": "YouTube",
    "subtitle": "BasedShark | Really garbage YT vids and bad gaming clips.",
    "image": youtube,
    "link": "https://www.youtube.com/channel/UCuKAOU_gRsTfLXYE7td5zlg"
},
{
   "title": "GitHub",
   "subtitle": "@Sharkiiie | It's mostly QBCore forks...",
   "image": github,
   "link": "https://github.com/sharkiiie"
},
{
   "title": "Ko-Fi",
   "subtitle": "Buy me some brown!",
   "image": kofi,
   "link": "https://ko-fi.com/lunafox69"
},
{
   "title": "Tebex",
   "subtitle": "Check out my FiveM scripts!",
   "image": tebex,
   "link": "https://lunafox.tebex.io/"
}]


export default items
